import React, { useState, useRef } from 'react'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Paper,
  ThemeProvider,
  createTheme,
  Box,
  Fade,
  CircularProgress,
} from '@mui/material'
import {
  AttachFile as AttachFileIcon,
  Send as SendIcon,
  Close as CloseIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  Movie as VideoIcon,
} from '@mui/icons-material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50', // Dark blue-gray
      light: '#34495e',
      dark: '#1a252f',
    },
    secondary: {
      main: '#e74c3c', // Soft red
      light: '#ec7063',
      dark: '#c0392b',
    },
    background: {
      default: '#ecf0f1',
      paper: '#ffffff',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#7f8c8d',
    },
    success: {
      main: '#27ae60', // Green
    },
    warning: {
      main: '#f39c12', // Orange
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
})

const getFileIcon = (fileType) => {
  if (fileType.startsWith('image/')) return <ImageIcon color='primary' />
  if (fileType.startsWith('video/')) return <VideoIcon color='secondary' />
  return <FileIcon color='action' />
}

export default function MultiAttachmentSend({selectedFiles,setSelectedFiles}) {
  const [isDragging, setIsDragging] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    if (event.target.files) {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(event.target.files),
      ])
    }
  }

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    )
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setIsDragging(false)
    if (event.dataTransfer.files) {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(event.dataTransfer.files),
      ])
    }
  }

  const handleSend = async () => {
    setIsSending(true)
    console.log('Sending files:', selectedFiles)
    // Simulate sending process
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setIsSending(false)
    setSelectedFiles([])
  }

  return (
    <ThemeProvider theme={theme}>
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple
      />
      <Box
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          marginBottom: '16px',
          padding: '16px',
          border: '2px dashed',
          borderRadius: '8px',
          transition: 'background-color 0.3s',
          borderColor: isDragging ? theme.palette.primary.main : '#ccc',
          backgroundColor: isDragging ? '#ecf0f1' : '#ffffff',
        }}
      >
        <Button
          variant='outlined'
          startIcon={<AttachFileIcon />}
          onClick={() => fileInputRef.current.click()}
          fullWidth
        >
          Drag and drop files here, or Select Files
        </Button>
      </Box>
      {selectedFiles.length > 0 && (
        <List
          style={{ marginBottom: '16px', maxHeight: '240px', overflow: 'auto' }}
        >
          {selectedFiles.map((file, index) => (
            <Fade in key={index}>
              <ListItem
                style={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  marginBottom: '8px',
                }}
              >
                <ListItemIcon>{getFileIcon(file.type)}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1'>{file.name}</Typography>
                  }
                  secondary={
                    <Typography variant='body2'>{`${(file.size / 1024).toFixed(
                      2
                    )} KB`}</Typography>
                  }
                />
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleRemoveFile(index)}
                  size='small'
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </ListItem>
            </Fade>
          ))}
        </List>
      )}
      {selectedFiles.length === 0 && (
        <Typography
          variant='body2'
          color='textSecondary'
          align='center'
          style={{ marginBottom: '16px' }}
        >
          No files selected
        </Typography>
      )}
      {/* <Button
          variant="contained"
          color="primary"
          startIcon={isSending ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
          onClick={handleSend}
          disabled={selectedFiles.length === 0 || isSending}
          fullWidth
        >
          {isSending ? 'Sending...' : `Send ${selectedFiles.length > 0 ? `(${selectedFiles.length})` : ''}`}
        </Button> */}
    </ThemeProvider>
  )
}
