import * as React from 'react'
import { Box, Card, CardContent, Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import MuiPhoneNumber from 'mui-phone-number'
import {
  AccountBox,
  Create,
  GroupAddTwoTone,
  Info,
  Payment,
  Send,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Modal } from '@mui/material'
import { SnackbarContext } from '../../../context/Snackbar'
import {
  getAllCollaboratorListApi,
  getAllUserListApi,
  getCollaboratorListApi,
  getOrgUserListApi,
  getTicketlist,
  imapCreateCollaboratorApi,
  imapCreateOrgUserApi,
} from '../../../services/configService'
import CustomTextArea from '../../../components/common/CustomTextArea'
import { set } from 'date-fns'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #2A67AC',
  boxShadow: 24,
  p: 2,
}

export default function Particularorguser({ state }) {
  const navigate = useNavigate()
  // const { state } = useLocation()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [isChecked, setIsChecked] = React.useState([])
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [collaboratorId, setCollaboratorId] = React.useState('')
  //   const [ticketstatus, setTicketstatus] = React.useState(state?.closed_status)
  const [upcollaboratorId, setUpcollaboratorId] = React.useState('')
  const [dltcollaboratorId, setDltcollaboratorId] = React.useState('')
  const [collaboratorData, setCollaboratorData] = React.useState([])
  const [collaboratorDataCopy, setCollaboratorDataCopy] = React.useState([])
  const [allCollaboratorData, setAllCollaboratorData] = React.useState([])
  const [collaboratorEmail, setCollaboratorEmail] = React.useState([])
  const [openManageCollaborator, setOpenManageCollaborator] =
    React.useState(false)
  const [newCollaboratorsdata, setNewCollaboratorsdata] = React.useState({
    name: '',
    email: '',
    phone: '',
    internal_note: '',
    reply_to: '',
  })

  const handleNewCollaboratorModal = () => {
    setOpen(true)
  }
  const handleChangeCollaborator = (e, v) => {
    setCollaboratorId(v?.id)
  }

  async function getAllCollaboratorData() {
    try {
      const res = await getAllUserListApi()
      if (res.status !== 204 && res.status !== 406) {
        setAllCollaboratorData(
          res.data?.filter((item) => item.status === 'active')
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleControl = () => setCollaboratorId('')
  const createNewCollaborator = async (change) => {
    const data = {
      org_id: state?.id,
      email: newCollaboratorsdata.email || change.default_email_id || '',
      name: newCollaboratorsdata.name || change.name || '',
      phone: newCollaboratorsdata.phone || change.phone || '', // not required
      internal_note:
        newCollaboratorsdata.internal_note || change.internal_note || '', // not required
    }

    try {
      const res = await imapCreateOrgUserApi(data, setError)

      if (res !== 204 && res !== 406) {
        setNewCollaboratorsdata({
          name: '',
          email: '',
          phone: '',
          internal_note: '',
        })
        setOpen(false)
        setOpenManageCollaborator(true)
        getData()

        setSeverity('success')
        setMes('User added successfully')
        setOn(true)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
    } finally {
    }
  }

  const handleContinue = () => {
    const change = allCollaboratorData.find(
      (item) => item.id === collaboratorId
    )

    if (data) {
      createNewCollaborator(change)
    }
    setCollaboratorId('')
  }

  const handleChangePhone = (e, v, v1, v2) => {
    let x = e
    let num = x.replace(/\D/g, '')
    // let dialCode = num.slice(0, 2)
    setNewCollaboratorsdata((pre) => ({
      ...pre,
      phone: num,
    }))
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setNewCollaboratorsdata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleClose = () => {
    setOpen(false)
    setCollaboratorId('')
  }

  async function getData() {
    try {
      const res = await getOrgUserListApi(state.id)
      if (res.status !== 204) {
        setData(res.message?.filter((item) => item.status == 'active'))
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log('data', state)

  const handleCheckboxChange = (event, row) => {
    console.log('event.target.checked', event.target.checked)
    setIsChecked((prev) => {
      let newChecked = [...prev]

      if (event.target.checked) {
        const newObject = {
          ...row,
          ded_amt: row.ded_amt ? row.ded_amt : 0,
          amount: row.balance_amount,
          tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
          payment_type: row.amount_type,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.p_id !== row.p_id)
      }

      return newChecked
    })
    SetEditable(true)
  }
  const handleLinkClick = (data) => {
    console.log('hello', data)
    navigate('/User-ticket', { state: data })
  }

  const selectedCollaborator = allCollaboratorData?.find(
    (item) => item.id === collaboratorId
  )
  
  React.useEffect(() => {
    getData()
    getAllCollaboratorData()
  }, [])

  React.useEffect(() => {
    if (error.duplicate_entry) {
      setSeverity('warning')
      setMes('User already exists')
      setOn(true)
    } else if (error.name || error.email) {
      setSeverity('warning')
      setMes('Please enter name and email')
      setOn(true)
    }
  }, [error])

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 75,

      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            // checked={isChecked[params.row.p_id]}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        )
      },
    },

    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        const maxWords = 8

        // Split the amount_type into an array of words
        const words = params.row?.name?.split(' ')

        // If the word count exceeds the limit, truncate the text and add ellipsis
        const truncatedText =
          words?.length > maxWords
            ? words?.slice(0, maxWords).join(' ') + '...'
            : params.row?.name
        return (
          <Button
            onClick={() => handleLinkClick(params.row)}
            style={{ textDecoration: 'underline', color: '#184E81' }} // Add this line
          >
            <strong style={{ color: '#184E81' }}>{truncatedText}</strong>
          </Button>
        )
      },
    },

    {
      field: 'email_id',
      headerName: 'From',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.default_email_id}</p>
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.status}</p>
      },
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row.created
              ? dayjs(params.row.created).format('ddd, DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',

          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            color: '#184E81',
            textDecoration: 'underline',
            fontWeight: '600',
          }}
        >
          {state?.name
            ? state.name.charAt(0).toUpperCase() + state.name.slice(1)
            : ''}
        </Typography>
        <Button
          variant='contained'
          startIcon={<GroupAddTwoTone />}
          onClick={handleNewCollaboratorModal}
          sx={{ mb: 1 }}
        >
          Add User
        </Button>
      </div>

      <StripedDataGrid
        rows={data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={styles}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#2A67AC'}
            borderBottom={'1px solid #C8C8C8'}
          >
            Add a User
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: '15px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
            bgcolor={'#d9edf7'}
            color={'#3a87ad'}
            border={'1px solid #bce8f1'}
          >
            {' '}
            <Info />
            Search existing users or add a new user.
          </Typography>
          <CardContent
            sx={{ padding: '0px', marginTop: '10px', marginBottom: '20px' }}
          >
            <Autocomplete
              disablePortal
              options={allCollaboratorData}
              getOptionLabel={(option) =>
                `${option.name} ${option.default_email_id}`
              }
              fullWidth
              sx={{
                // width: 'min(400px,100%)',
                // background: '#f2f2f2',
                borderRadius: 2,
              }}
              onChange={(e, v) => handleChangeCollaborator(e, v)}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {option.name}
                  </Typography>{' '}
                  &nbsp;
                  <Typography variant='subtitle2' color='#3a87ad'>
                    ({option.default_email_id})
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search By Name or Email'
                  // error={fieldError?.agent}
                  // helperText={
                  //   fieldError?.agent && 'Please select Agent'
                  // }
                  fullWidth
                />
              )}
              size='small'
            />

            {!collaboratorId && (
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '15px',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
                bgcolor={'#eee'}
                color={'#000'}
                border={'1px dotted #bbb'}
              >
                {' '}
                <Create />
                Create New User
              </Typography>
            )}
          </CardContent>
          {!collaboratorId && (
            <CardContent>
              <Grid container spacing={2} sx={{ gap: 2, marginLeft: '10px' }}>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Email Address:
                  </Typography>

                  <TextField
                    label='Email'
                    size='small'
                    sx={{ width: '350px' }}
                    name='email'
                    value={newCollaboratorsdata.email}
                    // error={error && error['full_name']}
                    onChange={(e) => handleChange(e, 'email')}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
                  <TextField
                    label='Name'
                    size='small'
                    sx={{ width: '350px' }}
                    name='name'
                    value={newCollaboratorsdata.name}
                    // error={error && error['full_name']}
                    onChange={(e) => handleChange(e, 'name')}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>Phone No:</Typography>
                  <MuiPhoneNumber
                    className='form_style'
                    variant='outlined'
                    name='phone'
                    value={newCollaboratorsdata.phone}
                    defaultCountry={'in'}
                    onChange={(e, v) => handleChangePhone(e, v)}
                    // error={error && error['contact']}
                    size='small'
                    sx={{ width: '350px' }}
                  />
                </Grid>
                <Grid
                  itm
                  md={11}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Internal Note:
                  </Typography>
                  <div style={{ padding: '0px', width: '350px' }}>
                    <CustomTextArea
                      placeholder={'Message...'}
                      name={'internal_note'}
                      value={newCollaboratorsdata.internal_note}
                      onChange={(e) => handleChange(e, 'internal_note')}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {collaboratorId && (
            <CardContent sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <AccountBox sx={{ color: 'lightgrey', fontSize: '100px' }} />
                </Grid>
                <Grid item md={9}>
                  <Typography variant='h6' color={'#3a87ad'}>
                    {selectedCollaborator?.name}
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    &lt;{selectedCollaborator?.default_email_id}&gt;
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      fontsize: '18px',
                      fontWeight: 'bold',
                      borderBottom: '2px dotted #000',
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,

                      mt: 1,
                    }}
                  >
                    Phone Number-{' '}
                    <p style={{ fontWeight: 500 }}>
                      {!selectedCollaborator?.phone === null
                        ? selectedCollaborator?.phone
                        : 'NA'}
                    </p>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    Internal Note-{' '}
                    <p style={{ fontWeight: 500 }}>
                      {!selectedCollaborator?.internal_note === null
                        ? selectedCollaborator?.internal_note
                        : 'NA'}
                    </p>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item md={6}>
                  <Button
                    color='error'
                    sx={{ border: '1px solid red' }}
                    onClick={handleClose}
                    size='small'
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <div style={{ display: 'flex', gap: 10, float: 'right' }}>
                    <Button
                      color='inherit'
                      sx={{ border: '1px solid #000' }}
                      onClick={handleControl}
                      size='small'
                    >
                      Add New User
                    </Button>
                    <Button
                      color='info'
                      size='small'
                      sx={{ border: '1px solid #3a87ad' }}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          )}
          {!collaboratorId && (
            <Button
              sx={{
                float: 'right',
                background: '#3a87ad',
                '&:hover': {
                  color: 'black',
                  backgroundColor: '#d9edf7',
                  // transition: 'backgroundColor 0.3s ease',
                },
              }}
              variant='contained'
              onClick={createNewCollaborator}
            >
              Add User
            </Button>
          )}
        </Card>
      </Modal>
    </Box>
  )
}
