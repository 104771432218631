import * as React from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import { alpha, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone'
import {
  allUnmappedApi,
  deleteSMTP,
  getAgent,
  getReceiptCollection,
  getSMTPListing,
  receiptDelete,
} from '../../../../services/configService'
import TableMenu from '../../../../components/table_modal/TableMenu'
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone'
import DeleteSweepTwoToneIcon from '@mui/icons-material/DeleteSweepTwoTone'
import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../../../context/Snackbar'
import {
  AutoMode,
  CheckCircleTwoTone,
  Download,
  Info,
  Update,
} from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import Deletecard from '../../../../components/card/Deletecard'
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
} from '@mui/material'
import { downloadAttachment } from '../../../../utils/common'
import SMTPConfigForm from './SmtpConfig'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#D0F0C0',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const paymentMode = {
  1: 'Cheque',
  2: 'NEFT/RTGS/IMPS',
  3: 'Cash',
  4: 'Online',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
}
export default function Smtplisting({ open, setOpen }) {
  const navigate = useNavigate()
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [receiptData, setReceiptData] = React.useState([])
  const [agent, setAgent] = React.useState([])
  const [deleteRow, setDeleteRow] = React.useState(false)

  const [updatedRowData, setUpdatedRowData] = React.useState(null)
  const [unmappedRow, setUnmappedRow] = React.useState(false)
  const [selectedDltId, setSelectedDltId] = React.useState(null)
  const [selectedUnMappedId, setSelectedUnMappedId] = React.useState(null)
  const [countdown, setCountdown] = React.useState(3) // Starting countdown value
  const [isYesDisabled, setIsYesDisabled] = React.useState(true)
  // const [open, setOpen] = React.useState(false)
  const [refreshData, setRefreshData] = React.useState(false)
  // const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setUpdatedRowData(null)
  }

  async function getReceiptlist() {
    try {
      const res = await getSMTPListing()
      if (res !== 204 && res !== 401) {
        setReceiptData(res.data)
        console.log('@!@!!@', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getAgentlist() {
    const get_agentlist = await getAgent()
    setAgent(get_agentlist?.data?.filter((item) => item.is_active === 1))
    console.log('get_agentlist', get_agentlist)
  }

  const customerID = receiptData?.map((item) => item?.customer_id)
  console.log('customerId', customerID)
  const customerName = agent?.map((item) => {
    return {
      id: item.id,
      name: item.name,
    }
  })
  const idToNameMap = customerName?.reduce((acc, customer) => {
    acc[customer.id] = customer.name
    return acc
  }, {})

  // Use the customerId list to get the corresponding names
  // const customerNames = customerID?.map((id) => idToNameMap[id])

  // console.log('customerName', customerNames)

  const handleMapped = (row) => {
    console.log('rowqwqwqwqwqw', row)
    const data = row
    navigate('/mapping', { state: data })
  }

  const handleUpdateMapped = (row) => {
    console.log('rowqwqwqwqwqw', row)
    const rowdata = row
    navigate('/update-mapping', { state: rowdata })
  }

  const handleEditRow = (row) => {
    console.log('rowqwqwqwqwqw', row)
    const data = row
    // navigate('/create-receipt', { state: data })
    setOpen(true)
    setUpdatedRowData(data)
  }
  const handleDeleteModal = (row) => {
    setSelectedDltId(row.id)
    setDeleteRow(true)
  }
  const handleUnmapeedModal = (row) => {
    setSelectedUnMappedId(row.id)
    setUnmappedRow(true)
  }
  const handledeleteModalClose = () => setDeleteRow(false)
  const handleunmappedModalClose = () => setUnmappedRow(false)

  const handleYesClick = async () => {
    const sendData = {
      receipt_id: selectedUnMappedId,
    }
    try {
      const res = await allUnmappedApi(sendData)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('All Queries Unmapped Successfully')
        setOn(true)
        getReceiptlist()
        setUnmappedRow(false)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleDeleteRow = async (row) => {
    try {
      const res = await deleteSMTP(selectedDltId)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('SMTP Deleted Successfully')
        setOn(true)
        getReceiptlist()
        handledeleteModalClose()
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }

  const columns = [
    { field: 'sno', headerName: 'ID', width: 90 },
    {
      field: 'mail_host',
      headerName: 'Mail Host',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return <strong>{params.row.mail_host}</strong>
      },
    },
    { field: 'smtp_port', headerName: 'SMTP Port', minWidth: 120 },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        const color =
          params.row?.status === 1
            ? 'info'
            : params.row?.status === 'active'
            ? 'success'
            : params.row?.status === 3
            ? 'warning'
            : params.row?.status === 4
            ? 'secondary'
            : 'error'
        return (
          <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
            <Chip
              label={
                params.row?.status === 'active'
                  ? 'Active'
                  : 'Inactive' || 'Unknown'
              }
              color={color}
              variant='outlined'
            />
            {params.row?.status === 'active' && (
              <CheckCircleTwoTone color='success' />
            )}
          </div>
        )
      },
      minWidth: 130,
      // flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return params.row.email == 'undefined' ? 'N.A' : params.row.email
      },
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params.row.created_at
          ? dayjs(params.row.created_at).format('DD-MM-YYYY')
          : dayjs(params.row.created_at).format('DD-MM-YYYY')
      },
    },
    {
      field: 'last_update_at',
      headerName: 'Last Update Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params.row.last_update_at
          ? dayjs(params.row.last_update_at).format('DD-MM-YYYY')
          : dayjs(params.row.last_update_at).format('DD-MM-YYYY')
      },
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 2,
                  title: 'Update SMTP',
                  icon: <EditNoteTwoToneIcon fontSize='small' color='info' />,
                  // disable: row?.balance_amount !== row?.amount ? true : false,
                  fn: () => handleEditRow(row),
                },
                {
                  id: 3,
                  title: 'Delete SMTP',
                  icon: (
                    <DeleteSweepTwoToneIcon fontSize='small' color='error' />
                  ),
                  disable: row?.status === 'active' ? true : false,
                  fn: () => handleDeleteModal(row),
                },
              ]}
            />
          </>,
        ]
      },
    },
  ]

  React.useEffect(() => {
    getReceiptlist()
    getAgentlist()
  }, [])

  React.useEffect(() => {
    if (refreshData === true) {
      getReceiptlist()
      getAgentlist()
      setRefreshData(false)
    }
  }, [refreshData])

  React.useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(timer) // Cleanup interval on component unmount
    } else {
      setIsYesDisabled(false)
    }
  }, [countdown])

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <StripedDataGrid
        rows={receiptData?.map((item, index) => ({
          ...item,

          sno: index + 1,
          payment_modee: paymentMode[item?.payment_mode],
        }))}
        getRowClassName={(params) =>
          params.row?.balance_amount !== params.row?.amount
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        disableRowSelectionOnClick
      />
      <Modal
        open={deleteRow}
        onClose={handleDeleteModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Deletecard
          btnText={'Delete'}
          onClickBack={handledeleteModalClose}
          onClickSubmit={handleDeleteRow}
          subheading={'Delete this SMTP?'}
        />
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          
          <SMTPConfigForm
            onCancel={handleClose}
            updatedRowData={updatedRowData}
            setUpdatedRowData={setUpdatedRowData}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        </Box>
      </Modal>
    </Box>
  )
}
