import React from 'react'
import useAuth from '../../../hooks/useAuth'
import SalesDash from '../../sales/dashboard/Dash/SalesDash'
import FinDash from '../../finance/fin_dash/FinDash'
import Statics from './Statics'

const MainDash = () => {
    const {auth} = useAuth()

    const dashboard = {
      1 : <Statics/>,
      3 : <SalesDash/>,
      4  : <FinDash/>
    }

  return (
    <div style={{backgroundColor :"aliceblue"}}>
       <Statics/>
    </div>
  )
}

export default MainDash