import React, { useContext, useState } from 'react'
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Fade,
  useTheme,
  Divider,
} from '@mui/material'
import { styled } from '@mui/system'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {
  acceptedTncApi,
  getTermsAndConditionlist,
} from '../../services/configService'
import useAuth from '../../hooks/useAuth'
import { SnackbarContext } from '../../context/Snackbar'

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  // marginTop: theme.spacing(4),
  boxShadow:
    ' rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'all 0.3s ease-in-out',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 5,
  textTransform: 'none',
  fontWeight: 600,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
  },
}))

export default function TermsAndConditionsCard({ setTermandcondtionopen }) {
  const { auth } = useAuth()
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [accepted, setAccepted] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [fitTncData, setfitTncData] = useState('')
  const [gitTncData, setgitTncData] = useState('')
  const [error, setError] = useState('')
  const theme = useTheme()

  async function getfitTermsAndCondition() {
    try {
      const res = await getTermsAndConditionlist(1)
      if (res.status !== 204) {
        setfitTncData(res.data[0])
        console.log('res.data', res.data)
      }
    } catch (error) {}
  }
  async function getgitTermsAndCondition() {
    try {
      const res = await getTermsAndConditionlist(2)
      if (res.status !== 204) {
        setgitTncData(res.data[0])
        console.log('data', res.data)
      }
    } catch (error) {}
  }

  const handleAcceptChange = (event) => {
    setAccepted(event.target.checked)
  }

  const handleSubmit = async () => {
    const sendData = {
      customer_id: auth.id,
      is_accepted: accepted ? 1 : 0,
      accepted_tnc_id: [fitTncData?.id, gitTncData?.id],
    }
    try {
      const res = await acceptedTncApi(sendData, setError)
      if (res.status !== 204 && res.status !== 406) {
        // setfitTncData(res.data[0])
        setSeverity('success')
        setMes('Terms and conditions accepted successfully')
        setOn(true)
        setTermandcondtionopen(false)
      }
    } catch (error) {}
  }

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  console.log('fitTncData', fitTncData)

  React.useEffect(() => {
    getfitTermsAndCondition()
    getgitTermsAndCondition()
  }, [])

  return (
    <Fade in={true} timeout={1000}>
      <StyledCard>
        <CardContent>
          <Typography variant='body1' color='text.secondary' paragraph>
            Please review our terms and conditions before proceeding. Your
            agreement is important to us.
          </Typography>
          <StyledButton
            variant='outlined'
            color='primary'
            onClick={handleOpenDialog}
            startIcon={<ArticleOutlinedIcon />}
          >
            Read Full T&C
          </StyledButton>
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={handleAcceptChange}
                  color='primary'
                />
              }
              label={
                <Typography variant='body2'>
                  I have read and accept the T&C.
                </Typography>
              }
            />
          </Box>
        </CardContent>
        <CardActions>
          <StyledButton
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleSubmit}
            disabled={!accepted}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Accept & Continue
          </StyledButton>
        </CardActions>

        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          scroll='paper'
          aria-labelledby='terms-dialog-title'
          maxWidth='md'
          PaperProps={{
            style: {
              borderRadius: theme.shape.borderRadius * 2,
            },
          }}
        >
          <DialogTitle id='terms-dialog-title'>
            <Typography variant='h6' fontWeight='bold'>
              Terms and Conditions
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography
              variant='h6'
              sx={{ mb: '10px', textDecoration: 'underline' }}
            >
              (FIT) Terms and Conditions
            </Typography>
            <Typography variant='body2'>
              <div
                dangerouslySetInnerHTML={{
                  __html: fitTncData?.description?.replace(/\n/g, '<br/>'),
                }}
              />
            </Typography>
            <Divider sx={{ mb: '20px', mt: '20px' }} />
            <Typography
              variant='h6'
              sx={{ mb: '10px', textDecoration: 'underline' }}
            >
              (GIT) Terms and Conditions
            </Typography>
            <Typography variant='body2'>
              <div
                dangerouslySetInnerHTML={{
                  __html: gitTncData?.description?.replace(/\n/g, '<br/>'),
                }}
              />
            </Typography>

            {/* Add more sections as needed */}
          </DialogContent>

          <DialogActions>
            <StyledButton onClick={handleCloseDialog} color='primary'>
              Close
            </StyledButton>
          </DialogActions>
        </Dialog>
      </StyledCard>
    </Fade>
  )
}
