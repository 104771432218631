import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import {
  adminSideLinks,
  financeSideLinks,
  salesSideLinks,
  userSideLinks,
} from '../../../routes/sidebar.routes'

import useAuth from '../../../hooks/useAuth'
import ProfileMenu from '../../../components/header/ProfileMenu'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import ParticleComponent from '../../../components/particles/Particles'
import { driverObj } from '../../../components/driver.js/Driver'
import { useMediaQuery } from '@mui/material'
import {
  AccountBox,
  Add,
  AirplaneTicket,
  ConfirmationNumber,
  DriveFileRenameOutline,
  Flight,
  ListAlt,
  ManageAccounts,
} from '@mui/icons-material'
import './style.css'
const drawerWidth = 245

const sidelink = {
  1: adminSideLinks,
  2: userSideLinks,
  3: salesSideLinks,
  4: financeSideLinks,
}

const iconMapping = {
  Dashboard: <AccountBox fontSize='small' />,
  'Create Query': <ConfirmationNumber fontSize='small' />,
  'Query List': <ListAlt fontSize='small' />,
  'All Leads': <DriveFileRenameOutline fontSize='small' />,
  'Agent List': <ManageAccounts fontSize='small' />,
  'Flight Info': <Flight fontSize='small' />,
  'All Booking': <AirplaneTicket fontSize='small' />,
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: 'black',
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  borderRight: '1px solid black',
  [theme.breakpoints.down('sm')]: {
    width: `0px`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Dash({ children }) {
  const theme = useTheme()

  const { auth } = useAuth()
  // console.log("auth",auth);
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(true)
  const [mobTabView, setMobTabView] = React.useState(false)

  const x = auth.menu_list
  console.log('xxx', x)

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setOpen(false)
        setMobTabView(true)
      } else {
        setMobTabView(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  console.log(pathname, 'pathname')

  React.useEffect(() => {
    if (auth.role === 2 && false) {
      driverObj.drive()
    }
  }, [])

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{ display: 'flex', height: 'calc(100vh - 64px)', padding: '0x' }}
      onKeyDown={(e) => {
        if (e.altKey === true && e.key === 78) navigate('/dashboard')
        else if (e.altKey === true && e.key === 65) navigate('/query-list')
        else if (e.altKey === true && e.key === 66) navigate('/booking')
        else if (e.altKey === true && e.key === 80) navigate('/profile')
      }}
    >
      <CssBaseline />
      {/* header */}
      <AppBar position='fixed' open={open} className='header_glass_effect'>
        <div>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => setOpen(true)}
              edge='start'
              sx={{
                marginRight: isSmallScreen ? 1 : 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div>
              <>
                {isSmallScreen ? (
                  <div style={{ marginTop: '7px' }}>
                    <img
                      src='/images/zealazerbaijanlogo10.png'
                      alt='logo'
                      style={{ width: '205px', height: '70px' }}
                    />
                  </div>
                ) : (
                  <img
                    src='/images/zealazerbaijanlogo10.png'
                    alt='logo'
                    style={{ width: '224px', height: '65px' }}
                  />
                )}
              </>
            </div>
          </Toolbar>
        </div>

        <div class='tooltip'>
          {pathname !== '/create-query' && (
            <button class='btn' onClick={() => navigate('/create-query')}>
              <Add
                sx={{ color: 'white', fontSize: '30px', fontWeight: '600' }}
              />
            </button>
          )}

          <div class='tooltiptext'>Create Query</div>
        </div>

        <ProfileMenu />
      </AppBar>

      {/* sidebar */}
      <Drawer
        variant={'permanent'}
        onClose={() => setOpen(false)}
        anchor={'left'}
        open={open}
        className='side_dash'
      >
        <ParticleComponent>
          <DrawerHeader>
            {open && (
              <img
                src='/images/sidebarplane.png'
                alt='plane'
                width={200}
                height={80}
              />
            )}
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                color: 'white',
                border: '1px solid rgba(255,255,255,0.6)',
              }}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List
            sx={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: '88vh',
              '&::-webkit-scrollbar': {
                width: '8px', // Set the width of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Color of the scrollbar thumb
                borderRadius: '4px', // Rounded corners for the scrollbar thumb
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555', // Color when hovering over the scrollbar thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', // Background color of the scrollbar track
              },
            }}
          >
            {/* {auth?.role === 3 ? (
              <>
                {!auth?.isLoading &&
                  auth?.menu_list?.map((item) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: 'block' }}
                      onClick={() => mobTabView && setOpen(false)}
                    >
                      <NavLink to={item.path}>
                        <ListItemButton
                          className={
                            pathname.includes(item.path) ? 'active_nav' : ''
                          }
                          sx={{
                            minHeight: 48,
                            justifyContent: open && 'initial',
                            // px: !open ? 2.5 : 5.5,
                            fontSize: '12px !important',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              paddingInline: !open ? '0px' : '34px',
                              minWidth: open && '210px',
                            }}
                            id={
                              item.menu_name === 'Terms & Conditions'
                                ? 'Terms'
                                : item.menu_name.replace(/\s/g, '')
                            }
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? '16px' : 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'rgba(255,255,255,0.8)',
                              }}
                            >
                              {iconMapping[item.menu_name]}
                            </ListItemIcon>
                            <ListItemText
                              primary={item.menu_name}
                              sx={{
                                opacity: open ? 1 : 0,
                                fontSize: '12px !important',
                              }}
                            />
                          </div>
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  ))}
              </>
            ) : ( */}
              {/* <> */}
                {!auth?.isLoading &&
                  sidelink[auth?.role]?.map((item) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: 'block' }}
                      onClick={() => mobTabView && setOpen(false)}
                    >
                      <NavLink to={item.path}>
                        <ListItemButton
                          className={
                            pathname.includes(item.path) ? 'active_nav' : ''
                          }
                          sx={{
                            minHeight: 48,
                            justifyContent: open && 'initial',
                            // px: !open ? 2.5 : 5.5,
                            fontSize: '12px !important',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              paddingInline: !open ? '0px' : '34px',
                              minWidth: open && '210px',
                            }}
                            id={
                              item.name === 'Terms & Conditions'
                                ? 'Terms'
                                : item.name.replace(/\s/g, '')
                            }
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? '16px' : 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'rgba(255,255,255,0.8)',
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={item.name}
                              sx={{
                                opacity: open ? 1 : 0,
                                fontSize: '12px !important',
                              }}
                            />
                          </div>
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  ))}
              {/* </> */}
            {/* )} */}
          </List>
          <Divider />
        </ParticleComponent>
      </Drawer>

      {/* main content */}
      <Box style={{ flexGrow: 1, width: '300px' }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  )
}
