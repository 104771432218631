import React, { useState } from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Seatinventorylisting from './Seatinventorylisting'
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button,
  Select,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { getSeatinventoryreport } from '../../../../services/configService'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { ArrowRightAlt } from '@mui/icons-material'

const Seatinventorymain = () => {
  const [bookings, setBookings] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [uniqueFlightCode, setUniqueFlightCode] = React.useState([])
  const [fromDate, setFromDate] = React.useState('')
  const [toDate, setToDate] = React.useState('')
  const [flightCode, setFlightCode] = useState('')

  async function getInventoryList() {
    try {
      const res = await getSeatinventoryreport()
      if (res !== 204 && res !== 401) {
        setBookings(res.data)
        const uniqueFlights = {}
        res.data.forEach((item) => {
          uniqueFlights[item.flight_code] = {
            flight_code: item.flight_code,
            departure_from: item.flight_departure_from,
            arrival_to: item.flight_departure_to,
          }
        })

        const flightCode = Object.values(uniqueFlights)
        setUniqueFlightCode(flightCode)
        setRows(res.data)
        console.log('@!@!!@', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log('uniqueFlightCode', flightCode)

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day)
  }

  const filterData = () => {
    const fromDateObj = fromDate ? parseDate(fromDate) : null
    const toDateObj = toDate ? parseDate(toDate) : null

    const filteredData = rows.filter((item) => {
      const itemDate = parseDate(item.d_date)
      const matchesFlightCode = !flightCode || item.flight_code === flightCode
      const matchesDateRange =
        (!fromDateObj || itemDate >= fromDateObj) &&
        (!toDateObj || itemDate <= toDateObj)

      return matchesFlightCode && matchesDateRange
    })
    setBookings(filteredData)
  }

 

  console.log('bookings', uniqueFlightCode)

  React.useEffect(() => {
    getInventoryList()
  }, [])

  return (
    <DashboardLayout heading='Seat Inventory' pathName='Seat Inventory'>
      <Card sx={{ marginBottom: '2px', padding: '8px' }}>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <div>
              <FormControl size='small' fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Flight Code
                </InputLabel>
                <Select
                  fullWidth
                  size='small'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={flightCode}
                  label='Flight Code'
                  onChange={(e) => setFlightCode(e.target.value)}
                  renderValue={(selected) => {
                    const findSelectedItem = (code) => {
                      return uniqueFlightCode.find((item) => item.flight_code === code);
                    };
                  
                    const selectedItem = findSelectedItem(selected)
                    return selectedItem ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedItem.flight_code}{' '}
                        <p
                          style={{
                            color: '#318CE7',
                            fontWeight: '500',
                            fontSize: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '8px',
                          }}
                        >
                          ( {selectedItem.departure_from} <ArrowRightAlt />{' '}
                          {selectedItem.arrival_to} )
                        </p>
                      </div>
                    ) : (
                      ''
                    )
                  }}
                >
                  {uniqueFlightCode.map((item) => (
                    <MenuItem
                      key={item.flight_code + item.d_date}
                      value={item.flight_code}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {item.flight_code}{' '}
                      <p
                        style={{
                          color: '#318CE7',
                          fontWeight: '500',
                          fontSize: '15px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        ( {item.departure_from} <ArrowRightAlt />{' '}
                        {item.arrival_to} )
                      </p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: { size: 'small', fullWidth: 'true' },
                  }}
                  format='DD-MM-YYYY'
                  label='From Date'
                  value={
                    fromDate
                      ? dayjs(fromDate, 'DD-MM-YYYY') // No need to format here
                      : null
                  }
                  onChange={(newDate) =>
                    setFromDate(newDate ? newDate.format('DD-MM-YYYY') : null)
                  }
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={3}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: { size: 'small', fullWidth: 'true' },
                  }}
                  format='DD-MM-YYYY'
                  label='To Date'
                  value={
                    toDate
                      ? dayjs(toDate, 'DD-MM-YYYY') // No need to format here
                      : null
                  }
                  onChange={(newDate) =>
                    setToDate(newDate ? newDate.format('DD-MM-YYYY') : null)
                  }
                  minDate={dayjs(fromDate)}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={1}>
            <Button onClick={filterData} variant='contained' color='submit'>
              Filter
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Seatinventorylisting bookings={bookings} />
    </DashboardLayout>
  )
}

export default Seatinventorymain
