import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import './form.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { emailRegex } from '../../utils/regex'
import {
  globalHandleChange,
  globalHandleError,
  setRole,
} from '../../utils/common'
import useAuth from '../../hooks/useAuth'
import { agentLogin, getAgentProfile } from '../../services/configService'
import Forgotpassword from './Forgotpassword'
import Loginotp from './Loginotp'
import { SnackbarContext } from '../../context/Snackbar'
import PasswordInput from '../common/PasswordInput'
import LoadingBtn from '../common/LoadingBtn'

const Loginform = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/dashboard'
  const { setAuth } = useAuth()

  const [open, setOpen] = React.useState(false)
  const [val, setVal] = useState(0)
  const [message, setMessage] = useState('')
  const [errorMsg, setErroMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState('')

  const checkValidate = () => {
    let valid = true

    if (!loginData.email) {
      setLoading(false)
      valid = false
      globalHandleError('email', setError, 'Please enter valid Email')
    } else if (loginData.email) {
      let validEmail = emailRegex.test(loginData.email)
      if (!validEmail) {
        valid = false
        globalHandleError('email', setError, 'Please enter valid Email')
      }
    }

    if (!loginData.password) {
      setLoading(false)
      valid = false
      globalHandleError('password', setError, 'Please enter valid Password')
    }
    return valid
  }

  const handleSubmit = async () => {
    setLoading(true)
    const valid = checkValidate()
    setMessage('')
    setOpen(true)
    if (valid) {
      const sendData = {
        email_id: loginData.email,
        password: loginData.password,
      }
      try {
        const res = await agentLogin(sendData, setError)
        if (res !== 406 && res !== 204) {
          setAuth({
            id: res?.data?.auth_id,
            role: setRole(res?.data?.role, res?.data?.sub_role),
            isAuthenticated: true,
            details: res?.data?.details,
            menu_list: res?.data?.menu_list,
            is_fit_tnc_accepted: res?.data?.is_fit_tnc_accepted,
            is_git_tnc_accepted: res?.data?.is_git_tnc_accepted,
          })
          setSeverity('success')
          setMes('Login Successfully')
          setOn(true)
          navigate(from, { replace: true })
        }
      } catch (err) {
        let c = err.message
        setSeverity('error')
        setMes(c)
        setOn(true)
        setErroMsg(c)
        console.log('error in login form ', err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const handelEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return val === 0 ? (
    <>
      {isSmallScreen ? (
        <div className='form-container'>
          <Typography
            className='header'
            sx={{ fontWeight: 'bold', textAlign: 'center', padding: '5px' }}
          >
            Welcome to Zeal Azerbaijan Airlines
          </Typography>
          <div className='textfield'>
            <Typography
              variant='h5'
              sx={{
                marginBottom: '5px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              LOG IN
            </Typography>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    className='form_style'
                    name='email'
                    value={loginData.email}
                    onChange={(e) =>
                      globalHandleChange(e, setLoginData, setError)
                    }
                    error={error.email ? true : false}
                    label='Email'
                    size='medium'
                    sx={{ marginTop: '5%', width: '80%', marginLeft: '38px' }}
                    required
                  />
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {error.email}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    variant='outlined'
                    className='form_style'
                    label='Password'
                    name='password'
                    value={loginData.password}
                    error={error.password ? true : false}
                    onChange={(e) =>
                      globalHandleChange(e, setLoginData, setError)
                    }
                    style={{ width: '80%', marginLeft: '38px' }}
                    size={'medium'}
                    required={true}
                  />
                  {/* <TextField
                  variant="outlined"
                  className="form_style"
                  name="password"
                  value={loginData.password}
                  onChange={(e) =>
                    globalHandleChange(e, setLoginData, setError)
                  }
                  error={error.password ? true : false}
                  type="password"
                  label="Password"
                  size="medium"
                  sx={{ width:"80%", marginLeft: "38px"}}
                  required
                
                />*/}
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {error.password}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className='loginbutton'
                    onClick={handleSubmit}
                    variant='contained'
                    color='authBtn'
                    size='medium'
                    sx={{
                      marginBottom: '2%',
                      fontWeight: 'bold',
                      color: 'black',
                      marginTop: '14px',
                      marginLeft: '38px',
                      width: '80%',
                    }}
                  >
                    LOG IN
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className='links'>
              <Button
                size='small'
                variant='text'
                sx={{
                  textDecoration: 'none',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                onClick={() => setVal(1)}
              >
                Login with OTP
              </Button>

              <Button
                size='small'
                variant='text'
                sx={{
                  textDecoration: 'none',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                onClick={() => setVal(2)}
              >
                Forgot password?
              </Button>
            </div>
            <NavLink to='/signup'>
              <Button
                variant='text'
                size='small'
                sx={{
                  marginTop: '1rem',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Create an account
              </Button>
            </NavLink>
          </div>
        </div>
      ) : (
        <Card
          className='formcard'
          elevation={6}
          sx={{ borderRadius: 4 }}
          onKeyDown={handelEnter}
        >
          <CardContent>
            <Typography className='header' sx={{ fontWeight: '600' }}>
              Welcome to Zeal Azerbaijan Airlines
            </Typography>
            <div className='textfield'>
              <Typography
                variant='h5'
                sx={{ marginBottom: '5px', fontWeight: 'bold' }}
              >
                LOG IN
              </Typography>

              <p style={{ color: 'red', fontSize: '13px', fontWeight: '420' }}>
                {message}
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    className='form_style'
                    name='email'
                    value={loginData.email}
                    onChange={(e) =>
                      globalHandleChange(e, setLoginData, setError)
                    }
                    error={error.email ? true : false}
                    label='Email'
                    size='small'
                    sx={{ width: '85%', marginTop: '5%', marginLeft: '24px' }}
                    required
                  />
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {error.email}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    label={'Password'}
                    variant='outlined'
                    className='form_style'
                    name='password'
                    value={loginData.password}
                    error={error.password ? true : false}
                    onChange={(e) =>
                      globalHandleChange(e, setLoginData, setError)
                    }
                    style={{ width: '85%', marginLeft: '24px' }}
                    size={'small'}
                    required={true}
                  />
                  {/* <TextField
                    variant="outlined"
                    className="form_style"
                    name="password"
                    value={loginData.password}
                    onChange={(e) =>
                      globalHandleChange(e, setLoginData, setError)
                    }
                    error={error.password ? true : false}
                    type="password"
                    label="Password"
                    size="small"
                    sx={{ width: "85%", marginLeft: "24px" }}
                    required
                  /> */}
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {error.password}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <LoadingBtn
                    className='loginbutton'
                    onClick={handleSubmit}
                    variant='contained'
                    size='small'
                    color={'authBtn'}
                    style={{
                      marginBottom: '2%',
                      fontWeight: 'bold',
                      color: 'black',
                      marginLeft: '24px',
                      marginTop: '14px',
                      // height : "40px"
                    }}
                    loading={loading}
                    text={'Log in'}
                  />
                  {/* {loading ? <CircularProgress size={24} color="inherit" /> : 'LOG IN '}
                    </LoadingBtn> */}
                </Grid>
              </Grid>
              <div className='links'>
                <Button
                  size='small'
                  variant='text'
                  sx={{
                    textDecoration: 'none',
                    fontSize: '11px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => setVal(1)}
                >
                  Login with OTP
                </Button>

                <Button
                  size='small'
                  variant='text'
                  sx={{
                    textDecoration: 'none',
                    fontSize: '11px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => setVal(2)}
                >
                  Forgot password?
                </Button>
              </div>
              <NavLink to='/signup'>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{
                    marginTop: '1rem',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    width: '100%',
                  }}
                >
                  Create an account
                </Button>
              </NavLink>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  ) : val === 1 ? (
    <Loginotp />
  ) : (
    <Forgotpassword />
  )
}

export default Loginform
