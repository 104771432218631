import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import MyStatefulEditor from '../../../../components/Text Editor/Texteditor'
import Tclisting from './Tclisting'
import { Create } from '@mui/icons-material'

const Createtermandcondition = () => {
  const [open, setOpen] = React.useState(false)
  const [createbtn, setCreatebtn] = React.useState('')
  const handleOpen = () => setOpen(true)

  return (
    <DashboardLayout
      heading='Term and Condition'
      pathName='Term and Condition'
      button={createbtn !== 2 ? true : false}
      onClick={handleOpen}
      btnText={'Create'}
      btnIcon={<Create />}
    >
      <Tclisting open={open} setOpen={setOpen} setCreatebtn={setCreatebtn} />
    </DashboardLayout>
  )
}

export default Createtermandcondition
