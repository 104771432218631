import React from 'react'
import ReactDOM from 'react-dom/client'
import './global.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'
import { SnackbarProvider } from './context/Snackbar'
import { ThemeProvider, createTheme,makeStyles  } from '@mui/material/styles'

const root = ReactDOM.createRoot(document.getElementById('root'))

const theme = createTheme({
  palette: {
    back: {
      main: '#504f37',
      dark: '#717070',
      contrastText: 'white',
    },
    backBlue: {
      main: '#424769',
      dark: '#61699f',
      contrastText: 'white',
    },
    submit: {
      main: '#2A6EBD',
      dark: '#4B8AD5',
      contrastText: 'white',
    },
    submitRed: {
      main: '#fb4570',
      dark: '#fb6b90',
      contrastText: 'white',
    },
    authBtn: {
      main: '#f9da34',
      dark: '#f9da34',
      contrastText: 'black',
    },
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: '#ad1457',
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#e91e63',
            border: '1px solid',
            backgroundColor: '#f8bbd0',
          }
        }
      }
    }
  },
})



root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
)
