import { ArrowUpward } from '@mui/icons-material'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'



export default function DataCard(props) {
  const { value } = props
  const [count, setCount] = useState('0'.padStart(value?.length, '0'))

  function animatedCounter() {
    let start = 0
    const end = Math.trunc(value)

    if (start === end) return end

    function updateCounter() {
      start += Math.ceil((end - start) / 10)

      if (start >= end) {
        start = end
      }
      setCount(String(start).padStart(end.toString().length, '0'))
      if (start < end) {
        requestAnimationFrame(updateCounter)
      }
    }
    updateCounter()
  }

  useEffect(() => {
    if (props.value) {
      animatedCounter()
    }
  }, [props])

  return (
    <Card
      sx={{ height: '150px',minWidth :"270px",  }}
      variant='outlined'
      style={{ boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px' }}
    >
      <CardContent>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <p
              style={{
                fontWeight: '600',
                color: 'rgb(108, 115, 127)',
                fontSize: '14px',
              }}
            >
              {props.heading}
            </p>
            <span style={{ display: 'flex',gap :"8px",alignItems : "end"  }}>
              <h6 style={{ fontSize: '25px', fontWeight: '600', }}>
                {count || 0}{' '}
              </h6>
              <Typography variant='caption' marginBottom={"4px"}>(Today)</Typography>
            </span>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                background: props.color,
                marginRight: 1,
                height: '46px',
                width: '46px',
              }}
            >
              <props.icon fontSize='small' sx={{ color: 'white' }} />
            </Avatar>
          </Grid>
        </Grid>
        <Grid
          container
          marginTop={'7px'}
          gap={'6px'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item display={'flex'} flexDirection={'column'}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'rgb(16, 185, 129)', marginTop: '6px' }}
            >
              {props.this_week}
            </Typography>
            <Typography variant='caption'>This Week</Typography>
          </Grid>
          <Grid item display={'flex'} flexDirection={'column'}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'rgb(16, 185, 129)', marginTop: '6px' }}
            >
              {props.this_month}
            </Typography>
            <Typography variant='caption'>This Month</Typography>
          </Grid>
          <Grid item display={'flex'} flexDirection={'column'}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'rgb(16, 185, 129)', marginTop: '6px' }}
            >
              {props.this_year}
            </Typography>
            <Typography variant='caption'>This Year</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
