import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import { Payment, Send } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Modal } from '@mui/material'
import { SnackbarContext } from '../../../context/Snackbar'
import {
  getTicketlist,
  getUserTicketlist,
} from '../../../services/configService'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function ImapLisiting({ orgdata, archived }) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])
  const [userticketData, setUserticketData] = React.useState([])
  const [orgticketData, setOrgticketData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [isChecked, setIsChecked] = React.useState([])
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  // const handleOpen = () => setOpen(true);

  async function getData() {
    try {
      const res = await getTicketlist()
      if (res.status !== 204) {
        archived === true
          ? setData(res.data.filter((item) => item.closed_status === 1))
          : setData(res.data.filter((item) => item.closed_status === 0))
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getUserTicketData(id) {
    try {
      const res = await getUserTicketlist(id)
      if (res.status !== 204) {
        setUserticketData(res.data)
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getUserTicketData(id) {
    try {
      const res = await getUserTicketlist(id)
      if (res.status !== 204) {
        setUserticketData(res.data)
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log('data', orgdata)

  const handleCheckboxChange = (event, row) => {
    console.log('event.target.checked', event.target.checked)
    setIsChecked((prev) => {
      let newChecked = [...prev]

      if (event.target.checked) {
        const newObject = {
          ...row,
          ded_amt: row.ded_amt ? row.ded_amt : 0,
          amount: row.balance_amount,
          tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
          payment_type: row.amount_type,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.p_id !== row.p_id)
      }

      return newChecked
    })
    SetEditable(true)
  }

  console.log('archived', data)

  const handleLinkClick = (data) => {
    console.log('hello', data)
    navigate('/imap-threads', { state: data })
  }

  React.useEffect(() => {
    getData()
  }, [])
  React.useEffect(() => {
    if (state?.id) {
      getUserTicketData(state.id)
    }
  }, [state])
  React.useEffect(() => {
    getData() // Call the async function
  }, [archived])

  const columns = [
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',
    //   width: 75,

    //   cellClassName: 'actions',
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         // checked={isChecked[params.row.p_id]}
    //         onChange={(event) => handleCheckboxChange(event, params.row)}
    //       />
    //     )
    //   },
    // },
    {
      field: 's_no',
      // type: 'S.No',
      headerName: 'S.No',
      width: 55,
    },
    {
      field: 'ticket_no',
      headerName: 'Case Id',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => handleLinkClick(params.row)}
            style={{ textDecoration: 'underline', color: '#184E81' }} // Add this line
          >
            <strong
              style={{ color: '#184E81' }}
            >{`#${params.row.ticket_no}`}</strong>
          </Button>
        )
      },
    },
    {
      field: 'last_updated',
      headerName: 'Last Update',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row.last_updated
              ? dayjs(params.row.last_updated).format('ddd, DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Subject',
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        const maxWords = 4

        // Split the amount_type into an array of words
        const words = params.row?.title?.split(' ')

        // If the word count exceeds the limit, truncate the text and add ellipsis
        const truncatedText =
          words?.length > maxWords
            ? words?.slice(0, maxWords).join(' ') + '...'
            : params.row?.title
        return (
          <Button
            onClick={() => handleLinkClick(params.row)}
            style={{ textDecoration: 'underline', color: '#184E81' }} // Add this line
          >
            <strong style={{ color: '#184E81' }}>{truncatedText}</strong>
          </Button>
        )
      },
    },

    {
      field: 'email_id',
      headerName: 'From',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.email_id}</p>
      },
    },

    {
      field: 'closed_status',
      headerName: 'Status',

      minWidth: 90,
      renderCell: (params) => {
        const color =
          params.row?.closed_status == 0
            ? '#90c9ee'
            : params.row.closed_status == 1
            ? '#f52f2fe3'
            : 'info'
        return params.row?.closed_status === 1 ? (
          <Chip
            label='Closed'
            sx={{ background: color, color: 'white', fontWeight: 500 }}
            variant='filled'
          />
        ) : (
          <Chip
            label='Open'
            sx={{ background: color, color: 'white', fontWeight: 500 }}
            variant='filled'
          />
        )
      },
    },
    // {
    //   field: 'assigned_to',
    //   headerName: 'Assigned To',
    //   flex: 1,
    //   minWidth: 185,
    //   renderCell: (params) => {
    //     return <p style={{ fontWeight: 500 }}>{params.row.assigned_to}</p>
    //   },
    // },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data.map((item, index) => ({
          ...item,
          s_no: index + 1,
        }))}
        getRowId={(row) => row.ticket_no}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
    </Box>
  )
}
